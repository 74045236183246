

/* { 
  name: '',
  website: '',
  logo: '',
  score: ,
  columns: ['', 'X', '',  '', '',''],
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */



const gamesAds=[
  /* dead link
      { 
        name: 'Slime Shadies',
        website: 'shadybattlegrounds.xyz',
        logo: 'slimeshadies.png',
        description: 'From a racing game to the full-scale player versus-player artillery game that includes several weapon types to take onto the battlefield!',
        socialHandles: ['twitter.com/SlimeShadies','discord.com/invite/WbTuz78TsB'],
        videos: [],
        longerDescription: 'Create and level your battle snail for increased health, shield, and energy. Change the looks of your snails on the battlegrounds with unique custom skins and trade your in-game assets. ',
        tags:[],
        owner: '',
      },
*/
      { 
        name: 'Bch.games',
        website: 'bch.games/play/uPkTdMCc',
        logo: 'bchgames.png',
        description: 'BCH.games is your favorite No-Bullshit™ crypto casino. Start playing now with your free bitcoin cash faucet',
        socialHandles: ['discord.com/invite/bch','twitter.com/bchdotgames'],
        videos: [],
        longerDescription: 'BCH.games offers a growing selection of original Bitcoin cash casino games that you will find nowhere else. These games are crafted in-house by our team and include cryptocurrency gambling classics like Roulette, Dice, Mines, Tower, Target, Crash and many more in the making.',
        tags:[],
        owner: '',
      },
      { 
        name: 'Craft',
        website: 'craft.cash',
        logo: 'craftcrash.png',
        description: 'Craft is a voxel drawing program implemented on the bitcoincash blockchain.',
        socialHandles: ['github.com/blockparty-sh/craft.cash'],
        videos: [],
        longerDescription: 'There are 256^3 possible positions and 255 colors to choose from :) for every 51 blocks you have queued for sync there is 1 transaction for every transaction there is a fee of 1000 satoshis.',
        tags:[],
        owner: '',
      },
      { 
        name: 'Spinbch',
        website: 'spinbch.com',
        logo: 'spinbch.png',
        description: 'Spinning wheel based game using zero-conf',
        socialHandles: [],
        videos: [],
        longerDescription: 'There are 6 different numbers on the wheel. Simply bet on one of the numbers and spin the wheel. If the wheel stops at your number, you win. If the wheel stops at 2x or 7x multiplier, you receive a free spin and the winnings will be multiplied by 2 or 7 accordingly. Multipliers are stackable infinitely so if you hit 7x7x7x, your winnings will be multiplied by 343 times.',
        tags:[],
        owner: '',
      },
      { 
        name: 'Satoshidice',
        website: 'satoshidice.com',
        logo: 'satoshidice.png',
        description: 'Provably fair dice game using zero-conf ',
        socialHandles: [],
        videos: [],
        longerDescription: 'You place a bet by sending bitcoins to one of the addresses listed in the bet options table. SatoshiDice DAPP sees this, evaluates win or lose and generates a return transaction. If you win, your bet is multiplied by the prize multiplier and that amount is sent back.',
        tags:[],
        owner: '',
      },
      { 
        name: 'A Fifth of Gaming',
        website: 'afifthofgaming.com',
        logo: 'afog.png',
        description: 'Automated gaming tournaments that pay out from BCH prize pools.',
        socialHandles: ['twitter.com/a5thofgaming', 'facebook.com/a.fifth.of.gaming','reddit.com/r/afog/', 'discord.gg/CbTz8AArRw', 't.me/a_fifth_of_gaming'],
        videos: ['https://www.youtube.com/embed/-3iXgm-0Gik'],
        longerDescription: 'Play all your favorite online multiplayer games in competitive real-money tournaments! ',
        tags:[],
        owner: '',
      },
      { 
        name: 'BASEPOKER',
        website: 'basepoker.com',
        logo: 'basepoker.png',
        description: 'Basepoker is an online poker platform that uses BCH as chips.',
        socialHandles: ['twitter.com/basepokerHQ', 'billetera.cash', 'www.facebook.com/basepoker', 'discord.com/invite/RpzCgkUHw9'],
        videos: ['https://www.youtube.com/embed/sd3_UTro5M0?si=7xpM39JdOtsn1qA3'],
        longerDescription: 'Basepoker is a web-based online poker platform that uses Bitcoin Cash as poker chips.',
        tags:[],
        owner: '',
      },
      { 
        name: 'bugs.cash',
        website: 'bugs.cash',
        logo: 'bugscash.png',
        description: 'A BCH faucet in the form of a online multiplayer game! Nectar fuels your empire. Gather BCH, build your nest, and unleash your swarm.',
        socialHandles: ['discord.gg/zPx8fVp'],
        videos: ['https://www.youtube.com/embed/P3oHaq5YKgs'],
        longerDescription: 'A BCH faucet in the form of a online multiplayer game! Dominate. Build. Conquer. Nectar fuels your empire. Build your army, expand your territory, and become the apex predator. Gather BCH, build your nest, and unleash your swarm. \n\
        Created by George Bence with art by DwellersArt and Niki Ciliers and music from Gateway 721',
        tags:[],
        owner: '',
      }
    ]

export default gamesAds