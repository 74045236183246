/* { 
  name: '',
  website: '',
  logo: '',
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */

const contentCreatorsAds =[
      { 
        name: 'Bitcoin Cash Podcast',
        website: 'bitcoincashpodcast.com',
        logo: 'bitcoincashpodcast.png',
        description: 'The Bitcoin Cash Podcast combines the latest cryptocurrency and Bitcoin Cash news with deep dives on BCH topics, featuring a variety of guests.',
        socialHandles: ['www.youtube.com/@BitcoinCashPodcast','t.me/thebitcoincashpodcast_discussion','t.me/thebitcoincashpodcast','twitter.com/TheBCHPodcast','www.reddit.com/r/BitcoinCashPodcast/','www.instagram.com/thebitcoincashpodcast/','www.twitch.tv/thebitcoincashpodcast','www.youtube.com/channel/UC22kOwMuIzJCOtAPjoIjkFA','odysee.com/@BitcoinCashPodcast:2','cashrain.com/bitcoincashpodcast','cointr.ee/bitcoincashpodcast','www.patreon.com/bitcoincashpodcast'],
        videos: ['https://www.youtube.com/embed/ixpuLmvNel4'],
        longerDescription: ` 
        The Bitcoin Cash Podcast, launched in January 2021, aims to accelerate adoption, provide accessible news updates, document the global adoption of new money, and make the process engaging and entertaining.`,
        tags:[],
        owner: '',
      },
      { 
        name: 'Casual BCH',
        website: 'open.spotify.com/show/4QuyQ5LHBb410sFXWINjd9',
        logo: 'casualbch.png',
        description: 'A laid back podcast mainly about BCH with Sayoshi Nakamario, VinRadiant, and Cheaplightning',
        socialHandles: ['www.youtube.com/playlist?list=PL0cthp4XeZ1OF0R332tnzjSul5ii-2uZH','open.spotify.com/show/4QuyQ5LHBb410sFXWINjd9'],
        videos: ['https://www.youtube.com/embed/jP8ZIUbpHL0?si=D43HWmjvgvA3ladz'],
        longerDescription: `Hosted by Sayoshi Nakamario with guest appearances from VinRadiant (creators of HelpMe.Cash) and Cheaplightning who have all been in crypto since ~2010.`,
        tags:[],
        owner: 'helpme.png',
      },
      { 
        name: 'General Protocols Spaces',
        website: 'youtube.com/@generalprotocols',
        logo: 'generalprotocols.png',
        description: 'General Protocols Twitter Spaces!',
        socialHandles: ['t.me/GeneralProtocols','twitter.com/GeneralProtocol','www.reddit.com/user/GeneralProtocols'],
        videos: ['https://www.youtube.com/embed/707-DPzhdA8'],
        longerDescription: `General Protocol hosts  LIVE Twitter Spaces to discuss the Bitcoin Cash Ecosystem! Miss it live? No worries! You can watch later on Twitter or Youtube.`,
        tags:[],
        owner: '',
      },
      { 
        name: 'Paytaca Talks',
        website: 'youtube.com/watch?v=jj167-51kfI&list=PL2DaalNSEP8K06J0aRBrjdi3GFNXpSuj1',
        logo: 'paytaca.png',
        description: 'Paytaca Talks is a series of episodes focusing about Paytaca app, BitcoinCash, cryptocurrency and other interrelated topics',
        socialHandles: ['open.spotify.com/show/3ntwfrqtUG8IRJdmteKD22','instagram.com/paytaca/','facebook.com/paytaca/','tiktok.com/@paytaca?lang=en','twitter.com/_paytaca_','t.me/PaytacaWalletApp'],
        videos: ['https://open.spotify.com/embed/episode/3DBRzAHjMhhhckpThPn2Yl'],
        longerDescription: `Paytaca Talks is a series of episodes with Joemar Taganna, President and CEO, and Aaron JP Almadro, the marketing director of Paytaca, focusing about Paytaca app, BitcoinCash, cryptocurrency and other interrelated topics with the goal of disseminating reliable information to cryptocurrency users as well as to the general public. Paytaca aims to be one of the prime movers in making this revolution transition from centralised to decentralized cash to happen. We would be at the forefront in this effort to introduce decentralized peer-to-peer electronic cash to the world by making it even more accessible to everyone, everywhere.In this Paytaca Talks, you can expect more in-depth information and tutorials about cryptocurrency and other related tech news. `,
        tags:[],
        owner: '',
      },
      { 
        name: 'La Economia P2P Podcast',
        website: 'youtube.com/@LaEconomiaP2P',
        logo: 'laeconomia.png',
        description: 'A podcast with Ian Blas from BCH Argentina in Spanish',
        socialHandles: ['t.me/LaEconomiaP2P','/twitter.com/laeconomiap2p','instagram.com/laeconomiap2p/'],
        videos: ['https://www.youtube.com/embed/6JrVWA67wWw'],
        longerDescription: 'Podcast de economía, libertad, privacidad y #BitcoinCash • P2P Electronic Cash, by Satoshi Nakamoto • Construyendo la economía paralela',
        tags:['Spanish'],
        owner: '',
      },
      { 
        name: 'BitcoinCash Foundation',
        website: 'youtube.com/@BitcoinCashFoundation',
        logo: 'bchf.png',
        description: 'The BitcoinCashFoundation post Weekly news on the BCH Ecosystem!',
        socialHandles: ['twitter.com/BCHF_ORG','t.me/BitcoinCashFoundation','t.me/BCHFNews','publish0x.com/bitcoin-cash2','medium.com/@BCHF_ORG,','read.cash/@BCHF','tiktok.com/@bchf_org'],
        videos: ["https://www.youtube.com/embed/JNI-ZrbCYAY" ],
        longerDescription: 'Someone needs to do something! The BCHF brings to you weekly news on the BCH ecosystem, keeps Coinmarketcap news and info upto date,  and more!',
        tags:[],
        owner: '',
      },
      { 
        name: 'Bitcoin Jason',
        website: 'youtube.com/@BitcoinJason',
        logo: 'jason.jpg',
        description: 'Bitcoin Cash Life Style, Merchant adoption services and consumer guide.',
        socialHandles: ['noise.app/u/BitcoinJason','cashrain.com/BitcoinJason'],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'Nemecis1000 ',
        website: 'youtube.com/@Nemecis1000/',
        logo: 'Nemecis1000.png',
        description: 'A youtube channel in spanish that makes videos about BCH and software',
        socialHandles: ['twitter.com/nemecis1000'],
        videos: ["https://www.youtube.com/embed/8SjPLt4aWGI"],
        longerDescription: 'Hola tengo Videos en español sobre algunas cosas de BitcoinCash y sofwrelibre',
        tags:['Spanish'],
        owner: '',
      },
      { 
        name: 'Paul',
        website: 'youtube.com/@rspl2024',
        logo: 'paul.png',
        description: 'A youtube channel on the BCH Ecosystem, also with videos in Spanish',
        socialHandles: ['twitter.com/Rspl382'],
        videos: ["https://www.youtube.com/embed/0h_9EIG4RLA"],
        longerDescription: '',
        tags:[],
        owner: '', 
      },
      { 
        name: 'Fiendish & Friends',
        website: 'youtube.com/@fiendishcrypto',
        logo: 'fiendishandfriends.png',
        description: 'Twitter spaces hosted by Fiendish interviewing guests about the BCH Ecosystem',
        socialHandles: ['twitter.com/FiendishCrypto', 'youtube.com/@fiendishcrypto'],
        videos: ["https://www.youtube.com/embed/IKGxVLtXrEs?si=FER6pB3UPHme_bpl"],
        longerDescription: 'Passionate voice of freedom, liberty, love, and p2p cash. ',
        tags:[],
        owner: '', 
      },
      { 
        name: 'BCH TV',
        website: 'twitch.tv/bitcoincashtv',
        logo: 'bchtv.png',
        description: 'Bitcoin Cash TV is a variety gaming show where the viewers can win BCH every stream & on social media.',
        socialHandles: ['twitch.tv/bitcoincashtv', 'kick.com/bitcoincashtv', 'parti.com/creator/twitter/BitcoinCashTV','rumble.com/bitcoincashtv', 'youtube.com/@BitcoinCashTV', 'twitter.com/BitcoinChadd'],
        videos: ["https://player.twitch.tv/?channel=bitcoincashtv&parent=www.helpme.cash"],
        longerDescription: 'Passionate voice of freedom, liberty, love, and p2p cash. ',
        tags:[],
        owner: '', 
      },
      { 
        name: 'Bitcoin Out Loud',
        website: 'youtube.com/@BitcoinOutLoud/videos',
        logo: 'bitcoinoutloud.png',
        description: 'Videos about Bitcoin (a peer to peer electronic) Cash (system)',
        socialHandles: ['youtube.com/@BitcoinOutLoud', 'twitter.com/BitcoinOutLoud'],
        videos: ["https://www.youtube.com/embed/o0jeLBJOr8Y?si=Qpu0vfyzfzTgz1VO"],
        longerDescription: '',
        tags:[],
        owner: '', 
      },
      { 
        name: 'No Hay Almuerzo Gratis Podcast',
        website: 'open.spotify.com/show/345C4CjsxMmDfni1Y3uLwP',
        logo: 'nohay.png',
        description: 'Youtube channel in Spanish that talks about various topics but is heavily centered in BCH.',
        socialHandles: ['twitter.com/AlmuerzoNo', 'odysee.com/@AlmuerzoNo:d', 'youtube.com/@AlmuerzoNo', 'open.spotify.com/show/345C4CjsxMmDfni1Y3uLwP'],
        videos: ["https://www.youtube.com/embed/nCUFRmE88Gk?si=2Xif0g43XjXQI0Ve"],
        longerDescription: '',
        tags:[`Spanish`], 
        owner: '',
      },
      { 
        name: 'Finca El Ágora',
        website: 'youtube.com/@fincaelagora/videos',
        logo: 'finca.png',
        description: 'Youtube channel in Spanish that makes tutorials for the BCH Ecosystem.',
        socialHandles: [],
        videos: ["https://www.youtube.com/embed/ZViBNCNz6hM?si=6DfcnjxM1HdTk6aJ"],
        longerDescription: 'Youtube channel in Spanish that makes tutorials for the BCH Ecosystem.',
        tags:[`Spanish`], 
        owner: '',
      },
    ]

export default contentCreatorsAds