import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import '../../App.css';
import Card from '../../components/Card';
import SocialCard from '../../components/SocialCard/SocialCard' //index file isnt working?
import ExchangeTable from '../../components/ExchangeTable'
import Modal from '../../components/Modal';
import Header from '../../components/Header';
import FAQ from '../FAQ';
import ImageTitleBackground from '../../assets/img/blockTitle.png';
import BigPic from '../../assets/img/casualLogo.png'
import AdImage from '../../assets/logos/bchbliss.png'

import DataAppsServices from '../../Data/Apps&Services'
import DataCashTokens from '../../Data/Cashtokens'
import DataContentCreators from '../../Data/ContentCreators'
import DataDevelopment from '../../Data/Development'
import DataDeveloperTools from '../../Data/DeveloperTools'
import DataExchanges from '../../Data/Exchanges'; 
import DataExplorers from '../../Data/Explorers'
import DataGames from '../../Data/Games'
import DataInfoStats from '../../Data/Info&Stats'
import DataLibrariesNodes from '../../Data/Libraries&Nodes'
//import DataNFTs from '../../Data/NFTs'
import DataSocials from '../../Data/Socials'
import DataStores from '../../Data/Stores'
import DataWallets from '../../Data/Wallets'
////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
interface Ad {
  website?: string;
  logo?: string;
  description?: string;
  socialHandles: string[];
  videos?: string[]; 
  longerDescription?: string; 
  tags?:string[];
}

function App() {
  const { category } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultCategory = queryParams.get('category') || 'all';

  const activeCategories = {
    'Content Creators': false,
    'Development': false,
    'Libraries & Nodes': false,
    'CashTokens': false,
    'Developer Tools': false,
    'Exchanges': false,
    'Info & Stats': false,
    'Apps & Services': false,
//    'NFT`s': false,
    'Stores': false,
    'Wallets': false,
    'Games': false,
    'Explorers': false,
    'Socials': false,
  };

  const adData = [
    'Apps & Services',
    'CashTokens',
    'Content Creators',
    'Development',
    'Developer Tools', 
    'Exchanges', 
    'Explorers', 
    'Games',
    'Info & Stats',
    'Libraries & Nodes',
//    'NFT`s',
    'Stores', 
    'Socials', 
    'Wallets'
  ]

  const [currentPage, setCurrentPage] = useState('home');
  const [visibleCategories, setVisibleCategories] = useState(Object.keys(activeCategories));
  //const [selectedAd, setSelectedAd] = useState<Ad | null>(null); // State to track the selected ad
  const [modalOpen, setModalOpen] = useState(false); // State to track modal if opened already
  const [showAllCategories, setShowAllCategories] = useState(true);
  const [selectedData, setSelectedData] = useState<Ad | null>(null);

  // Function to toggle the visibility of a category
  const toggleCategory = (categoryName: string) => {
    if (visibleCategories.includes(categoryName)) {
      // Category is currently visible, so hide it
      setVisibleCategories((prevVisibleCategories) =>
        prevVisibleCategories.filter((category) => category !== categoryName)
      );
    } else {
      // Category is currently hidden, so show it
      setVisibleCategories((prevVisibleCategories) => [
        ...prevVisibleCategories,
        categoryName,
      ]);
    }
  };

  // Function to show all categories
  const showAll = () => {
    setVisibleCategories(Object.keys(activeCategories));
    setShowAllCategories(true);
  };
  // Function to hide all categories
  const hideAll = () => {
    setVisibleCategories([]);
    setShowAllCategories(false);
  };
  const openModal = (data: Ad) => {
    if (!modalOpen) {
       setSelectedData(data);
       setModalOpen(true);
    }
 };
  // Function to close the modal
  const closeModal = () => {
    setSelectedData(null);
    setModalOpen(false);
  };

  return (
    <AppContainer>
      <BackgroundDivBg />
      <BackgroundDiv />
      <PageBackground />

      <StyledAdSpace>
        <img src={AdImage} alt="Ad" />
        <StyledAdText>
          BCH BLISS conference <a href="https://www.youtube.com/watch?v=ijaZei01AvM">is back!</a> <br/>
          May 13-15 in Ljubljana, Slovenia.<br/>
          Buy a ticket at <a href="https://tapswap.cash/trade/5a4f6b25243c1a2dabb2434e3d9e574f65c31764ce0e7eb4127a46fa74657691">Tapswap.cash</a>
        </StyledAdText>
      </StyledAdSpace>

      <StyledShowButton>
        <StyledToggle onClick={showAll}>Show All</StyledToggle>
        <StyledToggle onClick={hideAll}>Hide All</StyledToggle>
      </StyledShowButton>

      <StyledCategories>
        <StyledCatButton isactive={visibleCategories.includes('Apps & Services')} onClick={()=>toggleCategory('Apps & Services')}>Apps & Services</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('CashTokens')} onClick={()=>toggleCategory('CashTokens')}>CashTokens</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('Content Creators')} onClick={()=>toggleCategory('Content Creators')}>Content Creators</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('Development')} onClick={()=>toggleCategory('Development')}>Development</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('Developer Tools')} onClick={()=>toggleCategory('Developer Tools')}>Developer Tools</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('Exchanges')} onClick={()=>toggleCategory('Exchanges')}>Exchanges</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('Explorers')} onClick={()=>toggleCategory('Explorers')}>Explorers</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('Games')} onClick={()=>toggleCategory('Games')}>Games</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('Info & Stats')} onClick={()=>toggleCategory('Info & Stats')}>Info & Stats</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('Libraries & Nodes')} onClick={()=>toggleCategory('Libraries & Nodes')}>Libraries & Nodes</StyledCatButton>
        {/* <StyledCatButton isactive={visibleCategories.includes('NFT`s')} onClick={()=>toggleCategory('NFT`s')}>NFT's</StyledCatButton> */}
        <StyledCatButton isactive={visibleCategories.includes('Stores')} onClick={()=>toggleCategory('Stores')}>Stores</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('Socials')} onClick={()=>toggleCategory('Socials')}>Socials</StyledCatButton>
        <StyledCatButton isactive={visibleCategories.includes('Wallets')} onClick={()=>toggleCategory('Wallets')}>Wallets</StyledCatButton>
      </StyledCategories>

     


      {visibleCategories.length === 0 ? (
        <StyledEmpty>Click categories above to see entries</StyledEmpty>
      ) : (
        <StyledAds>
          {adData.map((category) => (
            <StyledCategory
            key={category}
            style={{
              display: visibleCategories.includes(category) 
                ? 'block'
                : 'none',
            }}
            >
              <StyledCategoryTitle>{category}</StyledCategoryTitle>

              <StyledBox>
                  {category === 'Apps & Services' &&
                    DataAppsServices.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {category === 'CashTokens' &&
                    DataCashTokens.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {category === 'Content Creators' &&
                    DataContentCreators.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {category === 'Development' &&
                    DataDevelopment.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {category === 'Developer Tools' &&
                    DataDeveloperTools.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {/*
                  {category === 'Exchanges' &&
                    <ExchangeTable data={DataExchanges} />
                  }
                  */}
                  {category === 'Exchanges' &&
                    DataExchanges.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {category === 'Explorers' &&
                    DataExplorers.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {category === 'Games' &&
                    DataGames.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {category === 'Info & Stats' &&
                    DataInfoStats.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {category === 'Libraries & Nodes' &&
                    DataLibrariesNodes.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {/*
                  {category === 'NFT`s' &&
                    DataNFTs.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  */}
                  {category === 'Stores' &&
                    DataStores.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
                  {category === 'Socials' &&
                    DataSocials.map((ad, index) => (
                      <SocialCard key={index} data={ad} />
                  ))}
                  {category === 'Wallets' &&
                    DataWallets.map((ad, index) => (
                      <StyledClick onClick={ () => openModal(ad)}>
                        <Card key={index} data={ad} />
                      </StyledClick>
                  ))}
              </StyledBox>
              
            </StyledCategory>
        ))}      
      </StyledAds>
      )}

      {modalOpen && <Modal onClose={closeModal} data={selectedData} />}
    </AppContainer>

  )
}

interface StyledCatButtonProps {
  isactive: boolean;
}

const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
const BackgroundDiv = styled.div`
  background-image: url(${BigPic});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100vh;
  width: 100%;
  z-index: -1;
  opacity: 0.4;
`;
const BackgroundDivBg = styled.div`
  background-color: #000000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100vh;
  width: 100%;
  z-index: -2;
  opacity: 1;
`;
const PageBackground = styled.div`
  background-color: #000000;
  background-image: 
    linear-gradient(45deg, rgba(10, 193, 142, 0.03) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(10, 193, 142, 0.03) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(10, 193, 142, 0.03) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(10, 193, 142, 0.03) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  min-height: 100vh; /* Ensures the background covers the full viewport height */
  width: 100%;
  position: fixed; /* Makes the background stay fixed while content scrolls */
  top: 0;
  left: 0;
  z-index: -3; /* Ensures the background stays behind all content */
`;
const StyledAds = styled.div`
  flex-grow: 1;

  padding: 0px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
  `
  const StyledEmpty = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  background: #000;
  font-size: 20px;
  width: 50%;
  color: white;
  border: 1px solid #0AC18E;
  margin: 50px auto 0;
  `
const StyledCategory = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  gap: 10px;
  border: 0px solid red;
`
const StyledCategoryTitle = styled.div`
  display: flex;
  background-color: #0AC18E;
  background-image: url(${ImageTitleBackground});
  color: #fff;
  justify-content: center;
  font-size: 24px;
  font-weight: 650;
  border: 1px solid white;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #0AC18E;
`
const StyledCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding-top: 10px;
`
const StyledShowButton = styled.div`
  position: relative;
  top: 5px;
  border: 0px solid red;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`
const StyledBox = styled.div`
  border: 0px solid green;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1240px;
  min-width: 410px;
  gap: 10px;
  margin: auto;
`
const StyledInnerBox =styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr)); 
  gap: 10px;
  > * {
    box-sizing: border-box;
  }
`
const StyledClick = styled.div`
  padding: 0px;
  display: grid;
  gap: 0px; 
`
const StyledCatButtonGrid = styled.div`
  display: flex;
  flex-wrap: wrap; 
  justify-content: flex-start; 
  gap: 10px; 
`;
const StyledCatButton = styled.div<StyledCatButtonProps>`
  background-color: ${props => props.isactive ? '#09af81' : '#000'};
  opacity: ${props => props.isactive ? '1.0' : '0.6'};
  color: #fff;
  padding: 5px 5px;
  border: 1px solid ${props => props.isactive ? '#fff' : '#0AC18E'};
  border-radius: ${props => props.isactive ? '10px' : '0px'};
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
    opacity: ${props => props.isactive ? '1.0' : '1.0'};
  }
  &:active {
    background-color: #004295;
  }
`;
const StyledToggle = styled.button`
  position: relative;
  background-color: #000;
  color: #fff;
  border: 1px solid #0AC18E;
  border-radius: 0px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004295;
  }
`;
const StyledAdSpace = styled.div`
  display: flex;
  justify-content: center;
  color: #f8f8f8;
  gap: 5px;
  align-items: center;
  width: 100%;
  height: 90px; 
  margin: 0px 0;
  background-color: rgba(0, 0, 0, 0.7);
  border-bottom: 1px solid #0AC18E;
  a {
    color: #0AC18E;
    text-decoration: none;
  }
  img {
    width: 90px;
    height: 90px;
  }
`;
const StyledAdText = styled.div`
  font-size: 14px;
  color: #f8f8f8;
  width: 220px;
  min-width: 200px;
  text-align: center;
`;
export default App;