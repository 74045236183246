


/* { 
  name: '',
  website: '',
  logo: '',
  score: ,
  columns: ['', 'X', '',  '', '',''],
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */

 const developmentAds  = [
      { 
        name: 'Cash Improvement Proposals',
        website: 'gitlab.com/im_uname/cash-improvement-proposals/-/blob/master/CHIPs.md?ref_type=heads',
        logo: 'nologo.png',
        description: 'CHIPs: Processes for consensus changes. A CHIP is ashared ecosystem process to achieve one positive change on the BCH network.',
        socialHandles: ['t.me/GeneralProtocols','read.cash/@GeneralProtocols/a-focus-on-the-bitcoin-cash-ecosystem-08-chips-f020b9bc','bitcoincashresearch.org/t/chips-a-more-detailed-process-recommendation/309/25'],
        videos: ['https://www.youtube.com/embed/9rB83MocRrQ'],
        longerDescription: 'A Cash Improvement Proposal (CHIP) is a formal document that outlines a proposed change or enhancement to the Bitcoin Cash (BCH) system. CHIPs serve as a structured method for the community to discuss, review, \n\
        and implement modifications aimed at improving the network\'s functionality, security, or efficiency. This process ensures that any alterations to the BCH protocol are thoroughly vetted and have community consensus before implementation.',
        tags:[],
        owner: '',
      },
      { 
        name: 'Bitcoincash Research',
        website: 'bitcoincashresearch.org/',
        logo: 'bchresearch.png',
        description: 'A common space where pioneers, innovators and developers can collaborate productively.',
        socialHandles: [],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },  
      { 
        name: 'CashTokens Protocol',
        website: 'cashtokens.org',
        logo: 'cashtokenslogo.png',
        description: 'CashTokens are digital assets that can be created and used on the global, decentralized Bitcoin Cash (BCH) network. These tokens can be issued by any person, organization, or decentralized application.',
        socialHandles: ['t.me/simpleledger'],
        videos: ['https://www.youtube.com/embed/81fJNke4yJI?si=yJ5-Hpbki0175F7x'],
        longerDescription: 'CashTokens are digital assets that can be created and used on the global, decentralized Bitcoin Cash (BCH) network. These tokens can be issued by any person, organization, or decentralized application. \n\
        Like Bitcoin Cash, tokens cannot be counterfeited. Tokens can be held by any contract, wallets can easily verify the authenticity of a token or group of tokens, and tokens cannot be inadvertently destroyed by non-token-aware software.',
        tags:[],
        owner: '',
      },
      { 
        name: 'Simple Ledger Protocol',
        website: 'slp.dev/',
        logo: 'slp.png',
        description: 'The Simple Ledger Protocol is a protocol developed in 2018 on top of the Bitcoin Cash blockchain using off-chain indexing to track token balances. Entirely separate and not related to from the CashTokens protocol.',
        socialHandles: ['t.me/simpleledger'],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'BCH Bliss',
        website: 'www.bliss.cash',
        logo: 'bchbliss.png',
        description: 'Yearly BCH builder-focused conference started in 2024 and hosted in Ljubliana, Slovenia during May',
        socialHandles: ['t.me/bchbliss', 'twitter.com/bchbliss', 'reddit.com/user/bchbliss'],
        videos: ['https://www.youtube.com/embed/ddVj8LepAPs?si=Pip6_69zLG5MAMPN'],
        longerDescription: 'Yearly BCH builder-focused conference started in 2024 and hosted in Ljubljana, Slovenia during May.',
        tags:[],
        owner: '',
      },
      { 
        name: 'General Protocols',
        website: 'generalprotocols.com/',
        logo: 'generalprotocols.png',
        description: 'Building the future of programmable money. Creators of AnyHedge and BCHBull',
        socialHandles: ['t.me/GeneralProtocols','twitter.com/GeneralProtocol','www.reddit.com/user/GeneralProtocols'],
        videos: ['https://www.youtube.com/embed/707-DPzhdA8'],
        longerDescription: `Established as a company in 2019, General Protocols prides itself on developing permissionless, trustless, borderless financial solutions for the whole world. We are a leading developer in the Bitcoin Cash ecosystem and working at the frontier of UTXO blockchain development.`,
        tags:[],
        owner: '',
      },
      { 
        name: 'Riften Labs',
        website: 'www.riftenlabs.com/',
        logo: 'riften.jpg',
        description: 'Riften Labs invented the Cauldron Protocol, Moria Stablecoin Protocol, and the on-chain oracle d3lphi.',
        socialHandles: ['discord.gg/KnpEYfNTnv','twitter.com/RiftenLabs'],
        videos: ['https://www.youtube.com/embed/syJmQS8wNSo?si=M-n2Xx23jzupDEeH&start=110'],
        longerDescription: `Riften Labs is a privately owned protocol research, development, and software company. Riften Labs invented the Cauldron Protocol, Moria Stablecoin Protocol for minting of real-world assets, and the on-chain price feed oracle d3lphi. The Cauldron Protocol is a set of smart contracts that enables trading and automated liquidity provisioning`,
        tags:[],
        owner: '',
      },
    ]
export default developmentAds