

/* { 
  name: '',
  website: '',
  logo: '',
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
  tags:['CashTokens'],
        owner: '',
}, */

const cashTokensAds =[
      { 
        name: 'Tapswap',
        website: 'tapswap.cash',
        logo: 'tapswap.png',
        description: 'Cashtokens Marketplace',
        socialHandles: ['t.me/TapSwapCash','twitter.com/mainnet_pat'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'Cauldron',
        website: 'cauldron.quest',
        logo: 'cauldron.png',
        description: 'Cauldron DEX powered by Bitcoin Cash built using Cash Tokens.',
        socialHandles: ['discord.com/invite/sXzdKq4eQU','twitter.com/CauldronSwap'],
        videos: [],
        longerDescription: 'Cauldron employs a novel micro-pools approach, allowing individual users to operate small-scale CPMM contracts. This design, taking advantage of Bitcoin Cashs UTXO model and capability to hold tokens and BCH in the same UTXO, aims for scalability and efficiency, fostering the growth of the token ecosystem on Bitcoin Cash',
        tags:['CashTokens'],
        owner: 'riften.jpg',
      },
      { 
        name: 'Fex',
        website: 'fex.cash/bulk',
        logo: 'fex.png',
        description: 'decentralized exchange (DEX) that allows users to trade CRC20 tokens and BCH.',
        socialHandles: ['twitter.com/FexCash','t.me/fexcash_disscusion'],
        videos: [],
        longerDescription: ' non-custodial exchange, which means that users retain full control of their assets at all times. The trading process is managed by smart contracts, which are self-executing contracts that are stored on the blockchain. This ensures that all transactions are transparent and secure. ',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'BCMR Generator',
        website: 'bcmr-generator.netlify.app',
        logo: 'nologo.png',
        description: 'Generate the BCMR Json file for your CashTokens project!',
        socialHandles: ['github.com/mr-zwets/bcmr-generator'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
        owner: 'mrzwetslogo.png',
      },
      { 
        name: 'Hashes For Cashers',
        website: 'hashes-ashen.vercel.app',
        logo: 'hashesforcashers.png',
        description: 'Use this site to learn about the power and utility of Bitcoin Cash Tokens and Smart Contracts',
        socialHandles: [],
        videos: [],
        longerDescription: 'A simple guessing game. Or learn about contracts and help yourself to the bounty! Use this site to learn about the power and utility of Bitcoin Cash Tokens and Smart Contracts. For Educational Purposes only.',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'CashTokens Studio',
        website: 'chipnet.cashtokens.studio',
        logo: 'cashstudio.png',
        description: 'Create and manage tokens and NFTs on CashTokens, by Paytaca',
        socialHandles: ['t.me/CashTokensStudio'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
        owner: 'paytaca.png',
      },
      /* dead link
      { 
        name: 'CRC20 Token Explorer',
        website: 'crc-token.vercel.app',
        logo: 'crc20explorer.png',
        description: 'A site that list CRC20 tokens',
        socialHandles: [],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
        owner: '',
      },
      */
      { 
        name: 'Tokenstork',
        website: 'tokenstork.com',
        logo: 'tokenstork.png',
        description: 'A site that list tokens built on Cashtokens. ',
        socialHandles: ['twitter.com/bitcoincashsite','t.me/Panmoni/487'],
        videos: [],
        longerDescription: 'TokenStork "Delivering beautiful new CashTokens on BCH since 2023" is a Panmoni project ',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'Bitcoin Cash Site Tutorials',
        website: 'bitcoincashsite.com/blog',
        logo: 'bitcoincashsite.png',
        description: 'CashToken development tutorials by George Donnelly.',
        socialHandles: ['t.me/bitcoincashsite'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'Open Token Registry',
        website: 'otr.cash',
        logo: 'otr.png',
        description: 'OpenTokenRegistry is used to display human-readable names, icons, ticker symbols, and other token information in many BCH wallets and software',
        socialHandles: ['twitter.com/OpenTokenRgstry'],
        videos: [],
        longerDescription: 'The registry is volunteer-maintained by active members of the Bitcoin Cash community. Its free to use in your software, and there are no fees to list new tokens or update token information.',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'Emerald DAO',
        website: 'emerald-dao.cash',
        logo: 'emeralddao.png',
        description: 'Emerald DAO is a simple Bitcoin Cash DAO template which acts as a fixed-term deposit savings vault with optional crowdfunded rewards scheme.',
        socialHandles: ['t.me/emeralddao','twitter.com/EmeraldDaoNFT'],
        videos: [],
        longerDescription: 'The DAO template consists of 3 contracts labeled "blueprint", "vault", and "safebox". The "blueprint" contract will be placed on a pure index-0 BCH UTXO which will be consumed to create the genesis transaction. The genesis transaction will create exactly one NFT with "minting" capability and lock it with the "vault" contract, which is a recursive covenant. Users will interact with the "vault" contract to create pairs of immutable NFTs.',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'NFT Viewer',
        website: 'viewer.sploit.cash/',
        logo: 'nftviewer.png',
        description: 'Browse CashTokens NFT Collections. ',
        socialHandles: ['github.com/acidsploit/cashtokens-nft-viewer'],
        videos: [],
        longerDescription: 'By searching for a Bitcoin Cash address you can browse, track and share NFT CashTokens collections.',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'CashScript Tutorials',
        website: 'www.youtube.com/@mrzwets',
        logo: 'mrzwets.png',
        description: 'Cashscript tutorials by Bitcoin Cash developer MrZwets. ',
        socialHandles: ['youtube.com/@mrzwets'],
        videos: ['https://www.youtube.com/embed/LT0veuGbYTQ'],
        longerDescription: '',
        tags:['CashTokens'],
        owner: 'mrzwetslogo.png',
      },
      { 
        name: 'Shinobi Art Engine',
        website: 'github.com/cashninjas/shinobi-art-engine',
        logo: 'shinobi.png',
        description: 'Shinobi Art Engine is a tool used to create BCH NFT collections from image layers! ',
        socialHandles: [],
        videos: ['https://www.youtube.com/embed/oiZm8S37lf8'],
        longerDescription: 'This is a fork of the excellent HashLips Art Engine to support BCH Cash Tokens created by the Cash Ninjas team!',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'Tokenaut',
        website: 'tokenaut.cash',
        logo: 'tokennaut.png',
        description: 'A curated list to help Navigate the NFT, Defi and Dapp projects in the CashTokens space.',
        socialHandles: ['twitter.com/GeukensMathieu'],
        videos: [],
        longerDescription: `Containing useful information on a Dapp like: Launch date, Status, Open sourced or closed source. \n
         NFT information like Mint price, how many have been minted and total supply, revenue, and floor price. `,
        tags:['CashTokens'],
        owner: 'mrzwetslogo.png',
      }, 
      { 
        name: 'Badgers.Cash',
        website: 'badgers.cash',
        logo: 'badgerscash.png',
        description: 'Stake BCH to earn the Badger token and spend them to get a discount on BadgerNFT\'s!',
        socialHandles: ['t.me/badgers_cash', 'github.com/SayoshiNakamario/BadgersStake'],
        videos: [],
        longerDescription: `The only way to create BadgerCoins is by temporarily locking BCH for an amount of time. \n
        No premine, no developer reservation, no investors.\n
        At the end of the lockup you can 'Unlock' which sends your locked BCH and the earned BadgerCoins back to the same address that initially created the lock.\n
        10 BadgerCoins can be used to get a 50% discount on the minting price of a BadgerNFT.`,
        tags:['CashTokens'],
        owner: 'helpme.png',
      },
      { 
        name: 'TokenBurner',
        website: 'www.tokenburner.cash/',
        logo: 'tokenburner.png',
        description: 'Provably burn fungable cashtokens on the Bitcoin Cash blockchain with the burner contract.',
        socialHandles: ['gitlab.com/dagurval/contract-burner'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'FundMe',
        website: 'fundme.cash',
        logo: 'fundme.png',
        description: 'Decentralized crowdfunding using BCH smart contracts, similar to Kickstarter or Flipstarter.',
        socialHandles: ['t.me/FundMe_Cash', 'github.com/SayoshiNakamario/CashStarter', 'twitter.com/FundMeCash'],
        videos: [],
        longerDescription: `A website for interacting with the decentralized \'CashStarter\' crowdfunding contracts on the BitcoinCash blockchain.\n
        Allows you to create fundraising campaigns with a funding goal amount which is not claimable until the goal is reached. \n
        Pledgers can refund their pledges at any time before the campaign is completed using the pledge NFT they receive. \n
        Stretch goals are supported as pledges can surpass the funding goal.`,
        tags:['CashTokens'],
        owner: 'helpme.png',
      },
      { 
        name: 'BCH Pump',
        website: 'https://bchpump.cash/pump',
        logo: 'bchpump.jpg',
        description: 'BCH Pump is a token launchpad on BitcoinCash network.',
        socialHandles: ['twitter.com/mainnet_pat'],
        videos: [],
        longerDescription: `Connect your WalletConnect2 enabled wallet such as Cashonize, click 'Create A Coin' button, fill out the form and you are ready to go.
        Once your token completes its bonding process, it will migrate to Cauldron.Quest and you will receive a 0.5 BCH reward.`,
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'BCH Gurus',
        website: 'bch.guru/',
        logo: 'bchguru.png',
        description: ' A unique, on-chain, peer-to-peer crypto price prediction game and NFT collection, built on Bitcoin Cash mainchain.',
        socialHandles: ['t.me/bch_guru','twitter.com/BCH_Guru','youtube.com/@BCH_Guru','reddit.com/user/bch_guru','.instagram.com/bch_guru/'],
        videos: ['https://www.youtube.com/embed/T_kWyoetnHE'],
        longerDescription: 'The BCH Gurus are a pioneering cashtokens NFT collection on Bitcoin Cash, designed to integrate with the BCH Guru Game ecosystem. So come on, join in and see if you have what it takes to become a BCH guru! ',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'Cash Ninjas',
        website: 'ninjas.cash',
        logo: 'cashninja.png',
        description: 'an NFT collection leveraging CashTokens technology on Bitcoin Cash with OpenAI enhancements for each Cash-Ninja! ',
        socialHandles: ['t.me/cashninjas','twitter.com/cashninjasbch','www.youtube.com/@CashNinjasBCH','github.com/cashninjas'],
        videos: ['https://www.youtube.com/embed/zhSRhdrKeLM'],
        longerDescription: 'The project is the result of a few BCH supporters crossing paths and teaming-up to build something awesome together. Then releasing open source tools for all the builders out there!',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'Bitcats Heroes Club',
        website: 'bitcatsheroes.club/',
        logo: 'bitcatshero.png',
        description: 'BitCats Heroes Club is an NFT collection that uses the CashTokens protocol in Bitcoin Cash.',
        socialHandles: ['twitter.com/BitCatsHeroes','t.me/BitCatsHeroesClub'],
        videos: [],
        longerDescription: 'This comprises of 10K unique hand-drawn digital images of cats.',
        tags:['CashTokens'],
        owner: 'paytaca.png',
      },
      { 
        name: 'Emerald DAO',
        website: 'emerald-dao.vercel.app/',
        logo: 'emeralddao.png',
        description: 'Emerald DAO is the first NFT CashToken! Where users lock there funds and recieved an NFT in return. No longer minting.',
        socialHandles: ['t.me/emeralddao','twitter.com/EmeraldDaoNFT'],
        videos: [],
        longerDescription: 'Emerald DAO is the first NFT CashToken! Where users lock there funds and recieved an NFT in return. No longer minting.',
        tags:['CashTokens'],
        owner: '',
      },
      { 
        name: 'Moria Stablecoins',
        website: 'www.moria.money',
        logo: 'moria.png',
        description: 'Asset-backed stablecoins with crypto collateral',
        socialHandles: ['t.me/moria_money','twitter.com/moria_money'],
        videos: [],
        longerDescription: `Moria is an open-source protocol that allows users to borrow tokens representing real-world assets like USD, gold, silver, or tradfi stocks with full and transparent backing by using on-chain crypto as collateral \n
        Created by Riften Labs. The first implementation of the protocol is the Moria USD stablecoin (MUSD).`,
        tags:['CashTokens'],
        owner: 'riften.jpg',
      },
      { 
        name: 'FutureBitcoinCash',
        website: 'futurebitcoin.cash',
        logo: 'futurebitcoincash.png',
        description: 'Future Bitcoin Cash (FBCH) are series of fungible tokens, with an incentive market for locking and unlocking Bitcoin Cash.',
        socialHandles: ['t.me/Future_Bitcoin_Cash'],
        videos: [''],
        longerDescription: `Future Bitcoin Cash (FBCH) are series of fungible tokens, with an incentive market for locking and unlocking Bitcoin Cash. Claim coupons by locking BCH for fungible tokens. Explore coupons and tokens per vaults. Or experiment with different coupon writing.`
        ,
        tags:[],
        owner: '',
      }, 
    ]

export default cashTokensAds
