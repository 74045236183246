import React, { useState } from 'react';
import styled from 'styled-components';
import ImageClose from '../../assets/img/close.png';
import ImageCloseHighlight from '../../assets/img/closeHighlight.png';
import ImageSubmit from '../../assets/img/submit.png';
import ImageSubmitHighlight from '../../assets/img/submitHighlight.png';
import DatePicker from 'react-datepicker';
import { createGlobalStyle } from 'styled-components';

import "react-datepicker/dist/react-datepicker.css";

//styling for date and time popup calendar
const DatePickerStyles = createGlobalStyle`
  .react-datepicker {
    background-color: #222;
    border: 0px solid #0AC18E;
  }
  .react-datepicker__day {
    color: #fff;
  }
  .react-datepicker__day--selected {
    background-color: #0AC18E;
  }
  .react-datepicker__day:hover {
    color: #000;
  }
  .react-datepicker__header {
    background-color: #099971;
  }
  .react-datepicker__current-month {
    color: #fff;
  }
  .react-datepicker__day-name {
    color: #fff;
  }

  .react-datepicker-time__header {
    color: #fff;
  }
  .react-datepicker__time-box {
    background: #222;
  }
  .react-datepicker__time-list-item {
    color: #fff; 
  }
  .react-datepicker__time-list-item:hover {
    color: #000; 
  }
  .react-datepicker__time-list-item--selected {
    background-color: #099971 !important; /* Change to your preferred color for selected time */
  }
`;

interface ModalEntryProps {
  onSubmit: (eventData: any) => void;
  onClose: () => void;
  start: Date
}

const ModalEntry: React.FC<ModalEntryProps> = ({ onSubmit, onClose, start }) => {
  const [title, setTitle] = useState('');
  const titleLength = 50;
  //const [eventTime, setEventTime] = useState('');

  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(new Date(startDate.getTime() + 60 * 60 * 1000)); // Adds one hour to startDate
  const [useUtc, setUseUtc] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState('');
  const [inputValue, setInputValue] = useState('');
  const descriptionLength = 700;
  const [socials, setSocials] = useState('');
  const [image, setImage] = useState('');
  const [video, setVideo] = useState('');

  const formatDuration = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    if (hours === 0) return `${mins} mins`;
    if (mins === 0) return `${hours}h`;
    return `${hours}h ${mins}m`;
  };

  // Update handleDurationChange to include validation
  const handleDurationChange = (minutes: number) => {
    //const validMinutes = Math.min(24 * 60, Math.max(15, minutes));
    const validMinutes = Math.max(15, minutes);
    setEndDate(new Date(startDate.getTime() + validMinutes * 60 * 1000));
  };

  const handleDirectInput = (input: string) => {
    // Remove any non-digit and non-colon characters
    const cleanInput = input.replace(/[^\d:]/g, '');
    setInputValue(cleanInput);

    // Parse the input when it matches the format XX:XX
    if (/^\d{1,2}:\d{2}$/.test(cleanInput)) {
      const [hours, minutes] = cleanInput.split(':').map(num => parseInt(num));
      const totalMinutes = (hours * 60) + minutes;
      handleDurationChange(totalMinutes);
      setIsEditing(false);
    }
  };

  // Format current duration for input field
  const getDurationInputValue = (): string => {
    const minutes = getCurrentDuration();
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, '0')}`;
  };

  const getCurrentDuration = (): number => {
    return Math.round((endDate.getTime() - startDate.getTime()) / (60 * 1000));
  };

  // Convert local time to UTC
  const toUtc = (date: Date): Date => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };

  // Convert UTC to local time
  const toLocal = (date: Date): Date => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  };

  // Handle start date change with timezone consideration
  const handleStartDateChange = (date: Date) => {
    const adjustedDate = useUtc ? toUtc(date) : date;
    setStartDate(adjustedDate);
    // Maintain the same duration when start time changes
    const duration = endDate.getTime() - startDate.getTime();
    setEndDate(new Date(adjustedDate.getTime() + duration));
  };

  // Handle end date change with timezone consideration
  const handleEndDateChange = (date: Date) => {
    const adjustedDate = useUtc ? toUtc(date) : date;
    setEndDate(adjustedDate);
  };

  const handleSubmit = () => {
    onSubmit({ title, description, startDate, endDate });
    onClose();
  };

  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  let formattedDate;
  if (start) {
    formattedDate = start.toLocaleDateString('en-US', options);
  }
  
  // Display dates in the selected timezone
  const displayStartDate = useUtc ? toLocal(startDate) : startDate;
  const displayEndDate = useUtc ? toLocal(endDate) : endDate;

  return (
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <DatePickerStyles />
      <StyledText>Enter Event details for:</StyledText>

      {start ? <StyledDate>{formattedDate}</StyledDate> : <></>}
      <StyledTimeDescription>Enter time using the events timezone. Calendar will convert it to UTC.</StyledTimeDescription>

      <StyledTime>
        <StyledDateTimePicker>
          <div>Start {useUtc ? '(UTC)' : '(Local)'}:</div>
          <DatePicker
            selected={displayStartDate}
            onChange={handleStartDateChange}
            showTimeSelect
            dateFormat="MMMM d, yyyy, h:mm aa"
          />
        </StyledDateTimePicker>
        
        <StyledDateTimePicker>
          <div>End {useUtc ? '(UTC)' : '(Local)'}:</div>
          <DatePicker
            selected={displayEndDate}
            onChange={handleEndDateChange}
            showTimeSelect
            dateFormat="MMMM d, yyyy, h:mm aa"
          />
        </StyledDateTimePicker>
      </StyledTime>

      <DurationSelector>
        <span>Events Duration: </span>
        <ButtonGroup>
          <SplitButton onClick={() => handleDurationChange(getCurrentDuration() - 15)}>
            -15m
          </SplitButton>
          <SplitButton onClick={() => handleDurationChange(getCurrentDuration() - 60)}>
            -1h
          </SplitButton>
        </ButtonGroup>

        {isEditing ? (
        <DurationInputGroup>
          <CustomInput
            type="number"
            min="0"
            value={Math.floor(getCurrentDuration() / 60)}
            onChange={(e) => {
              const hours = parseInt(e.target.value) || 0;
              const currentMins = getCurrentDuration() % 60;
              handleDurationChange((hours * 60) + currentMins);
            }}
            autoFocus
          />
          <span>h</span>
          <CustomInput
            type="number"
            min="0"
            max="59"
            value={getCurrentDuration() % 60}
            onChange={(e) => {
              const mins = parseInt(e.target.value) || 0;
              const currentHours = Math.floor(getCurrentDuration() / 60);
              handleDurationChange((currentHours * 60) + mins);
            }}
          />
          <span>m</span>
        </DurationInputGroup>
      ) : (
        <StyledDurationText onClick={() => setIsEditing(true)} style={{ cursor: 'pointer' }}>
          {formatDuration(getCurrentDuration())}
        </StyledDurationText>
      )}

        <ButtonGroup>
          <SplitButton2 onClick={() => handleDurationChange(getCurrentDuration() + 15)}>
            +15m
          </SplitButton2>
          <SplitButton2 onClick={() => handleDurationChange(getCurrentDuration() + 60)}>
            +1h
          </SplitButton2>
        </ButtonGroup>
      </DurationSelector>

      <StyledTitle>
        <span>Title: </span>
        <input placeholder="" value={title} onChange={(e) => setTitle(e.target.value)} />
      </StyledTitle>
      
      { title.length > titleLength &&
        <StyledTitleCounter>-{title.length - titleLength}</StyledTitleCounter>
      }
      
      <StyledDescription><textarea placeholder="Description. Inappropriate events will be deleted without refund." value={description} onChange={(e) => setDescription(e.target.value)} /></StyledDescription>
      
      { descriptionLength - description.length >= 0 ? (
        <StyledCharacterCounter>Characters left: {descriptionLength - description.length}</StyledCharacterCounter>   
      ) : (
        <StyledCharacterCounterRed>Characters left: -{description.length - descriptionLength}</StyledCharacterCounterRed>
      )}
        
     {/*
        <StyledSocials><input placeholder="Socials" value={socials} onChange={(e) => setSocials(e.target.value)} /></StyledSocials>
        <StyledLogo><input placeholder="Image" value={image} onChange={(e) => setImage(e.target.value)} /></StyledLogo>
        <StyledVideo><input placeholder="Video" value={video} onChange={(e) => setVideo(e.target.value)} /></StyledVideo>
    */}

        <SubmitButton onClick={handleSubmit}></SubmitButton>
        <CloseButton onClick={onClose} />
    </ModalContent>
  );
};

const ModalContent = styled.div`
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 550px;
  background-color: black;
  display: flex;
  z-index: 10;
  border: 1px solid #0AC18E;
  flex-direction: column; /* Stack content vertically */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: green;
  border-radius: 40px 40px 0px 40px;
  padding: 1rem;    /* Add padding if needed to prevent content sticking to edges */
  @media (max-width: 700px) { /* You can adjust the breakpoint as needed */
    width: 90%;
    height: 75%;
    top: 57%;
  }
  input {
    width: 80%;
  }
`;
const StyledDateTimePicker = styled.div`
  margin: 0px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  text-align: center;
  border: 0px solid red;
  .react-datepicker-wrapper {
    width: 100%; // Adjust width as needed
  }
`;
const StyledText = styled.div`
  position: relative;
  display: flex;
  color: #fff;
  justify-content: center;
  font-weight: 700;
  z-index: 5;
`;
const StyledTime = styled.div`
  position: relative;
  display: flex;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  z-index: 5;
`;
const StyledTimeDescription = styled.div`
  position: relative;
  display: flex;
  color: #fff;
  font-size: 14px;
  justify-content: center;
  z-index: 5;
`;
const StyledTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 4;
  color: #fff;
  gap: 8px;
  input {
    width: 100%;
    max-width: 350px;
  }
`;
const StyledDescription = styled.div`
  position: relative;
  top: 10px;
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 0px solid yellow;
  overflow-y: auto;
  overflow-x: hidden;
  textarea {
    font-size: 14px;
    width: 100%;
    resize: none;
    word-wrap: break-word;
    text-align: left;
    vertical-align: top;
  }
`;
const StyledCharacterCounter = styled.div`
  display: flex;
  color: #fff;
  font-size: 14px;
  position: relative;
  left: 0px;
  top: 20px;
  justify-content: right;
`;
const StyledCharacterCounterRed = styled.div`
  display: flex;
  color: red;
  font-size: 14px;
  position: relative;
  left: 0px;
  top: 30px;
  justify-content: right;
`;
const StyledTitleCounter = styled.div`
  display: flex;
  color: red;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  right: 26px;
  top: 215px;
  justify-content: right;
`;
const StyledDate = styled.div`
  display: flex;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  top: 0px;
  justify-content: center;
`;
const CloseButton = styled.button`
  background-image: url(${ImageClose});
  background-size: cover;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: transparent;
  width: 75px;
  height: 45px;
  border: 1px solid #0AC18E;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  z-index: 11;
  &:hover {
    background-image: url(${ImageCloseHighlight});
  }
`;
const SubmitButton = styled.button`
  background-image: url(${ImageSubmit});
  background-size: cover;
  position: absolute;
  bottom: 10px;
  left: 40%;
  background-color: transparent;
  width: 100px;
  height: 48px;
  border: 0px solid #0AC18E;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:hover {
    background-image: url(${ImageSubmitHighlight});
  }
  @media (max-width: 700px) { 
    width: 70px;
    height: 34px;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #0AC18E;

  /* Remove double borders between buttons */
  & > button:not(:last-child) {
    border-bottom: 1px solid #0AC18E;
  }

  /* Remove individual button borders */
  & > button {
    border: none;
  }
`;
const SplitButton = styled.button`
  color: #fff;
  padding: 2px 4px;
  cursor: pointer;
  font-size: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Different background colors for each button */
  &:first-child {
    background:rgb(85, 24, 24);
    &:hover {
      background:rgb(129, 36, 36);
    }
  }

  &:last-child {
    background:rgb(85, 24, 24);
    &:hover {
      background:rgb(129, 36, 36);
    }
  }
`;
const SplitButton2 = styled.button`
  color: #fff;
  padding: 2px 4px;
  cursor: pointer;
  font-size: 12px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Different background colors for each button */
  &:first-child {
    background:rgb(31, 105, 62);
    &:hover {
      background: rgb(41, 139, 82);
    }
  }

  &:last-child {
    background: rgb(31, 105, 62);
    &:hover {
      background: rgb(41, 139, 82);
    }
  }
`;
const DurationSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 5px 0;
  gap: 8px;

  span {
    min-width: 0px;
    text-align: center;
  }

  input[type="time"] {
    background: #222;
    color: #fff;
    border: 1px solid #0AC18E;
    border-radius: 4px;
    padding: 2px 4px;
    width: 80px;
    text-align: center;

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }
`;
const DurationInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  max-width: 150px;
  font-size: 14px;
`;

const CustomInput = styled.input`
  background: #222;
  color: #fff;
  border: 1px solid #0AC18E;
  border-radius: 4px;
  padding: 2px 4px;
  max-width: 30px;
  text-align: center;
  font-size: 14px;

  /* Hide spinner buttons */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &::placeholder {
    color: #666;
  }
`;
const StyledDurationText = styled.div`
  display: flex;
  color: #fff;
  font-size: 20px;
  position: relative;
  min-width: 75px;
`;
export default ModalEntry;
