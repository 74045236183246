  /* { 
  name: '',
  website: '',
  logo: '',
  score: ,
  columns: ['', 'X', '',  '', '',''],
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */
  
  const developerToolsAds =[
      { 
        name: 'Oracles',
        website: 'oracles.cash',
        logo: 'oracles.png',
        description: 'Cash Oracles are software that provides signed price information for various assets such that they can be used in the Bitcoin Cash scripting engine.',
        socialHandles: ['t.me/GeneralProtocols'],
        videos: [],
        longerDescription: `Each oracle is run by an operator, who can provide a signed metadata message stating who they are and can also provide contact and support information. \n
        In order for an oracle to provide price information, they must have a source for their pricing data. Each oracle can use their own source, and can provide signed metadata messages stating what price source they are observing. \n
        As each oracle observes a price point, they create an attestion in the form of a price oracle message. This message is then signed and distributed on the oracle network. `,
        tags:[],
        owner: 'generalprotocols.png',
      },
      { 
        name: 'Cashscript Playground',
        website: 'playground.cashscript.org/',
        logo: 'cashscript.png',
        description: 'A playground compiling and testing CashScript scripts. ',
        socialHandles: ['t.me/bch_compilers','twitter.com/CashScriptBCH'],
        videos: [],
        longerDescription: `For more information visit the mainwebsit cashscript.org 
        \n Smart contracts on Bitcoin Cash are stateless and UTXO-based. This model allows transactions to be verified independently and efficiently. Because there is no state that can impact the execution of these smart contracts, the results are deterministic and predictable.`,
        tags:['CashTokens'],
        owner: 'mrzwetslogo.png',
      },
      { 
        name: 'Bitauth IDE',
        website: 'ide.bitauth.com/',
        logo: 'bitauth.png',
        description: 'When you work in Bitauth IDE, you’re working on a Bitauth Template. Bitauth IDE is an integrated development environment for bitcoin authentication.',
        socialHandles: ['t.me/bitauth_ide','github.com/bitauth/bitauth-ide/issues','twitter.com/bitauth'],
        videos: [],
        longerDescription: 'When you work in Bitauth IDE, you’re working on a Bitauth Template. It’s a JSON file which fully describes the authentication protocol for a bitcoin wallet. Compatible wallet software can import your template and generate a fully-functional wallet, even for complex, multi-party protocols. Bitauth IDE lets you write, test, and export Bitauth templates.',
        tags:[],
        owner: '',
      },
      { 
        name: 'BCMR Generator',
        website: 'bcmr-generator.netlify.app/',
        logo: 'nologo.png',
        description: 'Generate the BCMR Json file for your CashTokens project!',
        socialHandles: ['github.com/mr-zwets/bcmr-generator'],
        videos: [],
        longerDescription: 'Fill-in form to create the BCMR Json file for a CashTokens project! This Json file can be hosted on the web on a domain name you control or can be pinned on IPFS with nft.storage.',
        tags:['CashTokens'],
        owner: 'mrzwetslogo.png',
      },
      { 
        name: 'Bitcoin Cash Testnet Faucet',
        website: 'tbch.googol.cash/',
        logo: 'bch.png',
        description: 'Testnet faucet for Bitcoin Cash. (tBCH)',
        socialHandles: [],
        videos: [],
        longerDescription: 'BCH has multiple Testnets, Testnet3 is the origianl one but it had grown too large because of some excessive testing of large blocks. Testnet 4 is the newer Chipnet is the new network with the latest rules that are going to be activated in the next network upgrade.',
        tags:[],
        owner: '',
      },
      { 
        name: 'Chaingraph',
        website: 'chaingraph.cash',
        logo: 'chaingraph.png',
        description: 'Chaingraph is a multi-node blockchain indexer and GraphQL API.',
        socialHandles: ['twitter.com/ChaingraphCash','github.com/bitauth/chaingraph','t.me/chaingraph_dev','hub.docker.com/u/chaingraph'],
        videos: [],
        longerDescription: 'Chaingraph provides an intuitive GraphQL API with support for efficient, nested queries and powerful blockchain analysis. And any query can be a live subscription, able to horizontally scale to millions of subscribers. Request precisely what you need in a single query, saving bandwidth and eliminating client-side data manipulation. Try some examples below.',
        tags:[],
        owner: '',
      },
      { 
        name: 'P2P-CASH Mainnet',
        website: 'p2p-cash.mainnet-pat.me/',
        logo: 'nologo.png',
        description: 'P2P-CASH is a library which allows to communicate with Bitcoin full nodes such as BCHN.',
        socialHandles: [],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'Meep',
        website: 'github.com/gcash/meep',
        logo: 'nologo.png',
        description: 'Meep is a command line Bitcoin Cash script debugger that lets you run scripts one OP at a time. ',
        socialHandles: ['github.com/gcash/meep'],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'Spedn',
        website: 'spedn.pl',
        logo: 'spedn.png',
        description: 'Spedn is a high level smart contracts language for Bitcoin Cash. It is designed for explicitness and safety.',
        socialHandles: ['t.me/bch_compilers','twitter.com/tendo_pein_sama'],
        videos: [],
        longerDescription: 'Spedn is an experimental tool. It is not recommended to be used on mainnet yet. Or at least do a lot of tests on testnet first.',
        tags:[],
        owner: '',
      },
      { 
        name: 'iguana',
        website: 'github.com/be-cash/iguana',
        logo: 'nologo.png',
        description: 'Bitcoin Cash Debugger',
        socialHandles: ['github.com/be-cash/iguana'],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'Bitbox-Flutter',
        website: 'github.com/tomas-forgac/bitbox-flutter',
        logo: 'nologo.png',
        description: 'Bitbox-Flutter for building Bitcoin Cash apps in Flutter. Integrated to Bitcoin.com’s REST API. Works with mainnet and testnet.',
        socialHandles: ['github.com/tomas-forgac/bitbox-flutter'],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'Bitcoin-kit-android',
        website: 'github.com/horizontalsystems/bitcoin-kit-android',
        logo: 'nologo.png',
        description: 'Bitcoin wallet toolkit implemented in Kotlin.',
        socialHandles: [],
        videos: [],
        longerDescription: `It consists of the following libraries: \n
          bitcoincore is a core library that implements a full Simplified Payment Verification (SPV) client in Kotlin \n
          bitcoinkit extends bitcoincore, makes it usable with Bitcoin network.\n
          bitcoincashkit extends bitcoincore, makes it usable with BitcoinCash(ABC) network. \n
          litecoinkit extends bitcoincore, makes it usable with Litecoin network. \n
          dashkit extends bitcoincore, makes it usable with Dash network. \n
          hodler is a plugin for bitcoincore, that makes it possible to lock certain amount of coins until some time in the future.\n
        `,
        tags:[],
        owner: '',
      },
      { 
        name: 'Bitcoin-kit-ios',
        website: 'github.com/horizontalsystems/bitcoin-kit-ios',
        logo: 'nologo.png',
        description: 'Wallet toolkit for Swift. This is a full implementation of SPV node.',
        socialHandles: [],
        videos: [],
        longerDescription: 'This is a full implementation of SPV node including wallet creation/restore, synchronization with network, send/receive transactions, and more. The repository includes the main BitcoinCore.swift and BitcoinKit.swift, BitcoinCashKit.swift and DashKit.swift separate pods.',
        tags:[],
        owner: '',
      },
      { 
        name: 'Python-bitcoincash',
        website: 'bitcoincash.network/python/',
        logo: 'nologo.png',
        description: 'This Python3 library provides an easy interface to the bitcoin data structures and protocol. The approach is low-level and "ground up".',
        socialHandles: ['github.com/dagurval/python-bitcoincash','twitter.com/merc1er?s=20'],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'BitCash',
        website: 'bitcash.dev/',
        logo: 'bitcash.png',
        description: 'BitCash is Python’s fastest Bitcoin Cash library and was designed from the beginning to feel intuitive, be effortless to use, and have readable source code.',
        socialHandles: ['github.com/pybitcash','twitter.com/merc1er?s=20'],
        videos: [],
        longerDescription: `BitCash is a fast and compliant Bitcoin Cash library with an extremely easy-to-use API. \n
          Robust API with sane defaults, making development a breeze \n
          Python’s fastest available implementation (100x faster than closest library) \n
          Compliant to implemented Bitcoin Improvement Protocols \n
          Available on all major platforms \n
          Extensive documentation \n`,
          tags:[],
        owner: '',
        },
      { 
        name: 'BitDB',
        website: 'docs.fountainhead.cash/docs/bitdb',
        logo: 'bitdb.png',
        description: 'Random Access Memory for Bitcoin. Bitcoin is the most secure hard disk that will ever exist. BitDB is the memory that lets you make the most out of it.',
        socialHandles: ['t.me/fountainheadcash','github.com/fountainhead-cash/bitd'],
        videos: [],
        longerDescription: `BitDB is an autonomous database that continuously synchronizes itself with Bitcoin, providing a flexible, fast, powerful, and portable query interface into the Bitcoin universe.\n
        By functioning as a "Memory" to Bitcoin's "Hard Disk", BitDB enables a whole new category of powerful in-memory data processing that used to be impossible with Bitcoin's JSON-RPC alone, and makes it as simple as a MongoDB query.`,
        tags:[],
        owner: '',
      },
      { 
        name: 'Fulcrum',
        website: 'github.com/cculianu/Fulcrum',
        logo: 'fulcrum.png',
        description: 'A fast & nimble SPV server for Bitcoin Cash, Bitcoin BTC, and Litecoin.',
        socialHandles: ['github.com/cculianu/Fulcrum'],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'DNSseeder',
        website: 'github.com/gcash/dnsseeder',
        logo: 'nologo.png',
        description: 'A Bitcoin Cash network crawler and DNS seeder written in Go.',
        socialHandles: ['github.com/gcash/dnsseeder'],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'Developers.cash',
        website: 'developers.cash/',
        logo: 'bch.png',
        description: 'Useful developer resources for building the Internet of Cash ',
        socialHandles: ['t.me/jimtendo'],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'bch.info',
        website: 'bch.info/en/developers',
        logo: 'bch101.png',
        description: 'Software libraries and SDKs',
        socialHandles: [],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'Bitcoin.com Tools',
        website: 'bitcoin.com/tools/',
        logo: 'bitcoincom.png',
        description: 'Explore what Bitcoin and other cryptocurrencies can do for you',
        socialHandles: [],
        videos: [],
        longerDescription: '',
        tags:[],
        owner: '',
      },
      { 
        name: 'IPFS-BCH',
        website: 'ipfs-bch.pat.mn',
        logo: 'nologo.png',
        description: 'IPFS-BCH allows to publish user content on IPFS with ease, in a permissionless fashion. No KYC or registartion needed',
        socialHandles: [],
        videos: [],
        longerDescription: 'It is an ideal tool to upload your BCMR data and use it in your applications. ',
        tags:[],
        owner: '',
      },
      { 
        name: 'CashTokens Airdrop Tool',
        website: 'github.com/mr-zwets/airdrop-tool',
        logo: 'nologo.png',
        description: 'A handy Javascript program to airdrop fungible tokens to NFT holders!',
        socialHandles: ['twitter.com/GeukensMathieu'],
        videos: [],
        longerDescription: 'A program to import a single-address wallet to airdrop fungible tokens to NFT holders. The tool uses mainnet-js and chaingraph to do the airdrop.',
        tags:['CashTokens'],
      },
      { 
        name: 'Opreturn Scanner',
        website: 'opreturn-scanner.netlify.app/',
        logo: 'nologo.png',
        description: 'Scan for data markers on the Bitcoin Cash blockchain!',
        socialHandles: ['twitter.com/GeukensMathieu', 'github.com/mr-zwets/opreturn-scanner'],
        videos: [],
        longerDescription: 'An Opreturn Scanner to find all occurrences of a specific opreturn marker on-chain, for example, for \'BCMR\' finds 3417 txids at time of writing.',
        tags:['CashTokens'],
        owner: 'mrzwetslogo.png',
      },
      { 
        name: 'FullStack',
        website: 'fullstack.cash/',
        logo: 'cashstack.png',
        description: 'BCH Blockahin As-A-Service. FullStack provides back end infrastructure, so front end developers can hit the ground running.',
        socialHandles: ['t.me/permissionless_software','github.com/Permissionless-Software-Foundation'],
        videos: [],
        longerDescription: 'Our full stack software allows modern apps to talk to the blockchain, and use permissionless money. This is the buisness logic that makes one app different from another. This is the user interface that end-users get to interact with. This is the layer where front end developers play. ',
        tags:[],
        owner: '',
      },
      { 
        name: 'Dropship.cash',
        website: 'dropship.cash/',
        logo: 'dropship.png',
        description: 'Send BCH and CashTokens to multiple recipients',
        socialHandles: ['t.me/dropship_cash'],
        videos: [],
        longerDescription: 'Dropship.cash is a tool to flexibly airdrop BCH and fungible CashTokens. You can create your custom airdrop lists, load FT and NFT holders from blockchain, use custom airdrop strategies and more.',
        tags:[],
        owner: '',
      },
    ]

export default developerToolsAds