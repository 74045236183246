import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ImageClose from '../../assets/img/close.png';
import ImageCloseHighlight from '../../assets/img/closeHighlight.png';
import { format, utcToZonedTime } from 'date-fns-tz';

interface Event {
  id: string;
  title: string;
  start: Date;
  end: Date;
  description: string;
  // ... any other properties
}

interface ModalCalendarProps {
  onClose: () => void;
  event: Event;
  onDelete: (id: string) => void;
}

const ModalCalendar: React.FC<ModalCalendarProps> = ({ onClose, event, onDelete }) => {
  const [description, setDescription] = useState<React.ReactNode>('');
  const [selectedTimeZone, setSelectedTimeZone] = useState('UTC');
  const timeZones = [
    "Pacific/Midway",       // UTC-11:00
    "Pacific/Honolulu",     // UTC-10:00
    "America/Anchorage",    // UTC-09:00
    "America/Los_Angeles",  // UTC-08:00
    "America/Denver",       // UTC-07:00
    "America/Chicago",      // UTC-06:00
    "America/New_York",     // UTC-05:00
    "America/Caracas",      // UTC-04:30
    "America/Halifax",      // UTC-04:00
    "America/St_Johns",     // UTC-03:30
    "America/Buenos_Aires", // UTC-03:00
    "Atlantic/South_Georgia", // UTC-02:00
    "Atlantic/Azores",      // UTC-01:00
    "UTC",                  // UTC+00:00
    "Europe/London",        // UTC+01:00
    "Europe/Berlin",        // UTC+02:00
    "Africa/Cairo",         // UTC+03:00
    "Asia/Dubai",           // UTC+04:00
    "Asia/Kabul",           // UTC+04:30
    "Asia/Karachi",         // UTC+05:00
    "Asia/Kolkata",         // UTC+05:30
    "Asia/Kathmandu",       // UTC+05:45
    "Asia/Dhaka",           // UTC+06:00
    "Asia/Rangoon",         // UTC+06:30
    "Asia/Bangkok",         // UTC+07:00
    "Asia/Hong_Kong",       // UTC+08:00
    "Asia/Tokyo",           // UTC+09:00
    "Australia/Darwin",     // UTC+09:30
    "Australia/Sydney",     // UTC+10:00
    "Pacific/Noumea",       // UTC+11:00
    "Pacific/Auckland",     // UTC+12:00
    "Pacific/Fiji",         // UTC+13:00
    "Pacific/Tongatapu"     // UTC+14:00
    // ... add more time zones as needed
  ];

  const handleTimeZoneChange = (e: any) => {
    setSelectedTimeZone(e.target.value);
  };

  const convertTimeToTimeZone = (date: Date, timeZone: string) => {
    const zonedDate = utcToZonedTime(date, timeZone);
    // Format the date and time in the desired format
    return format(zonedDate, "h:mma", { timeZone });
  };

  const closeModal = () => {
    onClose();
  };

//////////////////////////////////////////  
/////  Delete an existing event
//////////////////////////////////////////
  const handleDeleteEvent = async () => {
    if (event === null) {
      return; // Exit the function if selectedEvent is null
    }

    // Send DELETE request to server
    const response = await fetch(`https://node.helpme.cash/api/events/${event.id}`, {
    //const response = await fetch(`http://localhost:3004/api/events/${event.id}`, {
      method: 'DELETE',
    });
    if (response.ok) {
      // Remove event from local state
      onDelete(event.id);
      closeModal();
    }
  };

  //Convert event.date to readable format
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  
  let formattedDate;
  let formattedTime;
  let timeZoneConvertedTime;

  if (event) {
    const startDate = new Date(event.start);
    formattedDate = startDate.toLocaleDateString('en-US', options);

    const hours = startDate.getUTCHours();
    const minutes = startDate.getUTCMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const twelveHourClockHours = hours % 12 || 12;
    formattedTime = `${twelveHourClockHours}:${minutes < 10 ? '0' + minutes : minutes}${ampm} UTC`;

    timeZoneConvertedTime = convertTimeToTimeZone(startDate, selectedTimeZone); //convert starttime so user timezone selection works
  }

  //Convert event.date to readable format
  let formattedStart;
  if (event) {
    const startTime = new Date(event.start);
    formattedDate = startTime.toLocaleDateString('en-US', options);
  }

  return (
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <StyledDateContainer>
        {/* Dropdown for timezone selection */}
        <StyledCustomTimezone>
          <StyledSelect value={selectedTimeZone} onChange={handleTimeZoneChange}>
            {timeZones.map(timeZone => (
              <option key={timeZone} value={timeZone}>{timeZone}</option>
            ))}
          </StyledSelect>
          Your time: {timeZoneConvertedTime}
        </StyledCustomTimezone>
        <StyledColumn>
          <StyledDate>{formattedDate}</StyledDate>
          <StyledTime>{formattedTime}</StyledTime>
        </StyledColumn>
      </StyledDateContainer>

    <StyledTitle>{event.title}</StyledTitle>
    
    <StyledDescription>{event.description}</StyledDescription>

    <CloseButton onClick={closeModal} />
    {/* <button onClick={handleDeleteEvent}>Delete</button> */}
    </ModalContent>
  );
};


const ModalContent = styled.div`
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85vw;
  max-width: 700px;
  min-width: 300px;
  height: 85vw;
  max-height: 550px;
  min-height: 200px;
  background-color: black;
  align-items: center;
  z-index: 10;
  border: 1px solid #0AC18E;
  flex-direction: column; /* Stack content vertically */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 40px 40px 0px 40px;
  padding: 1rem;
`;
const StyledDate = styled.div`
  display: flex;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  top: 0px;
  justify-content: left;
  left: 40px;
  @media (max-width: 600px) {
    left: 10px;
    font-size: 18px;
  }
}
`;
const StyledCustomTimezone = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  justify-content: center;

`;
const StyledSelect = styled.select`
  position: relative;
  display: flex;
  color: #000;
  font-size: 16px;
  @media (max-width: 832px) {
    font-size: 12px;
  }
`;
const StyledText = styled.div`
  position: relative;
  display: flex;
  color: #fff;
  justify-content: center;
  font-weight: 700;
  z-index: 5;
`;
const StyledTime = styled.div`
  position: relative;
  display: flex;
  color: #fff;
  justify-content: left;
  font-weight: 700;
  z-index: 5;
  left: 60px;
  @media (max-width: 832px) { 
    font-size: 18px;
    left: 20px;
  }
`;
const StyledID = styled.div`
  position: absolute;
  bottom: 0px;
  display: flex;
  color: #fff;
  justify-content: center;
  font-size: 14px;
  z-index: 5;
`;
const StyledDescription = styled.div`
  position: relative;
  top: 5px;
  height: 85%;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
const StyledTitle = styled.div`
  position: relative;
  display: flex;
  font-weight: 700;
  justify-content: center;
  z-index: 5;
  border-bottom: 2px solid #0AC18E;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
const StyledWebsite = styled.a`
  font-weight: bold;
  font-size: 26px;
  position: absolute;
  left: 80px;
  top: 0px;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #0AC18E;
  }
`;
const StyledSharedContent = styled.div`
  position: absolute;
  top: 80px;
  height: 80%;
  display: flex;
  flex-direction: column;
  border: 0px solid yellow;
  overflow-y: auto;
  overflow-x: hidden;
`;
const VideoContainer = styled.div`
  border: 0px solid #0AC18E;
  display: flex;
  justify-content: center;
  height: 100%;
  border-radius: 00px 0px 0px 20px;
`;
const SocialsLinkContainer = styled.div`
  display: flex;
  gap: 4px; /* Adjust the gap between links */
  font-size: 15px;
  position: absolute;
  left: 80px;
  top: 45px;
  justify-content: center;
  i {
    color: #fff;
  }
  i:hover {
    color: #0AC18E;
  }
`;
const CloseButton = styled.button`
  background-image: url(${ImageClose});
  background-size: cover;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: transparent;
  width: 75px;
  height: 45px;
  border: 0px solid #0AC18E;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  &:hover {
    background-image: url(${ImageCloseHighlight});
  }
`;
const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
`;
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;



export default ModalCalendar;
