import React, { useState, ReactNode} from 'react';
import styled, { keyframes } from 'styled-components'
import Modal from '../Modal'


const darken = (hex: string, percent: number): string => {
  if (!hex) return '';
  let num = parseInt(hex.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) - amt,
      B = (num & 0x0000FF) - amt,
      G = (num >> 8 & 0x00FF) - amt;

  return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 + (B < 255 ? B < 1 ? 0 : B : 255)).toString(16).slice(1);
};

type TagColors = {
  [key: string]: string;
};

const tagColors: TagColors = {
  'cashtokens': '#76B041', // Example color for CashTokens
  'nsfw': '#B22222',
  'beta': '#009CC8',
  'spanish': '#8962FF',
  'mandarin': '#B66600',
  'german': '#FFCC00',
  'in development': '#B60000',
  // Add more tag types and their colors here  #B60000  B22222
};

const getTagColor = (tag: string): string => {
  //console.log(`Tag: '${tag}'`); // Log the tag to debug
  const color = tagColors[tag.toLowerCase().trim()] || '#0AC18E';
 // console.log(`Color: '${color}'`); // Log the color to debug
  return color;
};

interface Ad {
  name?: string;
  website?: string;
  logo?: string;
  description?: string;
  socialHandles: string[];
  videos?: string[]; 
  longerDescription?: string; 
  tags?:string[];  
  owner?: string;
}

interface CardProps {
  children?: ReactNode;
  data: Ad;
}

  const Card: React.FC<CardProps> = ({ data }) => {
    const [modalVisible, setModalVisible] = React.useState(false);
  
    const openModal = () => {
      //setModalVisible(true);
    };
  
    const closeModal = () => {
      console.log('Closing modal, state: ', modalVisible);
     setModalVisible(false);
    };

    const handleCardClick = (e: React.MouseEvent) => {
      const websiteLink = e.currentTarget.querySelector('a');
      if (websiteLink && (websiteLink === e.target || websiteLink.contains(e.target as Node))) {
       return; // Do nothing if clicking the website link
      }
    };

    // Function to map social handles to icons when using Font Awesome
const getSocialIcon = (handle: string) => { // Step 1: font awesome icons search through this list for their icon

  // Check for Font Awesome icons
  if (handle.includes('facebook.com')) return 'facebook';
  if (handle.includes('twitter.com')) return 'twitter';
  if (handle.includes('t.me')) return 'telegram';
  if (handle.includes('discord')) return 'discord';
  if (handle.includes('github.com')) return 'github';
  if (handle.includes('linkedin.com')) return 'linkedin';
  if (handle.includes('tiktok.com')) return 'tiktok';
  if (handle.includes('youtube.com')) return 'youtube';
  if (handle.includes('instagram.com')) return 'instagram';
  if (handle.includes('twitch.tv')) return 'twitch';
  if (handle.includes('patreon.com')) return 'patreon';
  if (handle.includes('reddit.com')) return 'reddit';
  if (handle.includes('medium.com')) return 'medium';
  if (handle.includes('gitlab')) return 'gitlab';
  if (handle.includes('git.xulu')) return 'gitlab';
  if (handle.includes('play.google.com')) return 'google-play';
  if (handle.includes('docker.com')) return 'docker';
  if (handle.includes('slack.com')) return 'slack';
  if (handle.includes('wiki')) return 'wikipedia-w';
  if (handle.includes('spotify')) return 'spotify';

  //custom
  if (handle.includes('noise.app')) return 0;
  if (handle.includes('odysee.com')) return 0;
  if (handle.includes('cointr.ee')) return 0;
  if (handle.includes('cashrain.com')) return 0;
  if (handle.includes('linktr.ee')) return 0;
  if (handle.includes('read.cash')) return 0;
  if (handle.includes('publish0x.com')) return 0;
  // Add more mappings for other social platforms as needed
  return 'external-link-alt'; // Default icon for unknown handles
};

const getCustomIcon = (handle: string) => { // Step 2: custom icons search through this list for their image
  
  if (handle.includes('odysee.com')) return require('../../assets/logos/odysee.png');
  if (handle.includes('cointr.ee')) return require('../../assets/logos/cointree.png');
  if (handle.includes('cashrain.com')) return require('../../assets/logos/cashrain.png');
  if (handle.includes('noise.app')) return require('../../assets/logos/noisecash.png');
  if (handle.includes('linktr.ee')) return require('../../assets/logos/linktree.png');
  if (handle.includes('read.cash')) return require('../../assets/logos/readscash.png');
  if (handle.includes('publish0x.com')) return require('../../assets/logos/publish0x.png');
}

    return (
      <StyledAd onClick={handleCardClick}> 
        {data.logo ? <StyledLogo src={require(`../../assets/logos/${data.logo}`)} alt={data.website} /> : null }
        
        {data.owner ? 
          <StyledOwner>
            <StyledOwnerLogo src={require(`../../assets/logos/${data.owner}`)} alt={data.website} />
          </StyledOwner> 
        : null }

        <StyledWebsite 
          href={`https://${data.website}`} 
          target="_blank" 
          rel="noopener noreferrer" 
          textLength={data.name?.toString().length || 0}
          onClick={(e) => e.stopPropagation()}
        >
          {data.name}
        </StyledWebsite>

        <StyledDescription textLength={data.description?.toString().length || 0}>
          {data.description}
        </StyledDescription>
          
        <SocialsLinkContainer>
          {data.socialHandles.map((handle: string, handleIndex: number) => (
            <a
              key={handleIndex}
              href={`https://${handle}`}
              target="_blank"
              rel="noopener noreferrer"
            >
            {typeof getSocialIcon(handle) === 'string' ? (
              <i className={`fab fa-${getSocialIcon(handle)} fa-2x`} />
            ) : (
              <StyledIcon src={getCustomIcon(handle)} alt={handle} />
              //<img src={getSocialIcon(handle) || defaultImage} alt={handle} />
            )}
            </a>
          ))}
        </SocialsLinkContainer>

        <TagsContainer>
          {data.tags?.map((tag, index) => {
            const baseColor = getTagColor(tag.trim());
            return (
              <StyledTag key={index} baseColor={baseColor || '#0AC18E'}>
                {tag}
              </StyledTag>
            );
          })}
        </TagsContainer>
          
       {modalVisible && <Modal onClose={closeModal} data={data} />} 
      </StyledAd>
    );
  };


interface StyledWebsiteProps {
  textLength: number;
}
interface StyledDescriptionProps {
  textLength: number;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledAd = styled.div`
  background-color: black;
  border: 1px solid #00cc66;
  margin: 1px auto;
  margin-bottom: 5px;
  padding: 1px;
  color: #F8F8F8;
  height: 120px;
  width: 400px;
  max-width: 400px;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  border-radius: 40px 40px 0px 40px;
  &:hover {
    background-color: #021d15;
  }
`;
const StyledLogo = styled.img`
  position: absolute;
  top: -8px;
  left: -8px;
  width: 75px;
  height: 75px;
  border-radius: 180px 180px 180px 180px;
  border: 2px solid #0AC18E;
  background-color: black;
  z-index: 1;
  &:hover {
    animation: ${spin} 5s linear infinite;  /* Apply the spinning animation */
  }
`;
const StyledOwner = styled.div`
  position: absolute;
  top: 60px;
  left: 0px;
  width: 60px;
  height: 60px;
  border-radius: 0px 0px 0px 35px;
  border: 0px solid red;
  background-color: black;
  overflow: hidden;
  contain: paint;
  opacity: 0.4;
  z-index: 0;
`;
const StyledOwnerLogo = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
const StyledWebsite = styled.a<StyledWebsiteProps>`
  font-weight: bold;
  font-size: ${props => {
    if (props.textLength > 45) return '14px';
    if (props.textLength > 35) return '16px';
    return '20px';
  }};
  position: absolute;
  left: 80px;
  top: 0px;
  text-decoration: none;
  color: #FAEBE5;
  &:hover {
    color: #0AC18E;
  }
`;
const SocialsLinkContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
  font-size: 10px;
  position: relative;
  top: 98px;
  width: 100%;
  justify-content: flex-start;
  border: 0px solid red;
  i {
    color: #fff;
  }
  i:hover {
    color: #0AC18E;
  }
`;
const StyledDescription = styled.p<StyledDescriptionProps>`
  position: absolute;
  left: 80px;
  margin-top: 25px;
  text-align: left;
  font-size: ${props => {
    if (props.textLength > 220) return '10px';
    if (props.textLength > 180) return '12px';
    return '14px';
  }};
`;
const StyledTag = styled.span<{ baseColor?: string }>`
  background-image: ${props => props.baseColor
    ? `linear-gradient(to bottom, ${props.baseColor}, ${darken(props.baseColor, 40)})`
    : 'none'};
  color: #F8F8F8;
  padding: 1px 3px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 0px;
  white-space: nowrap;
  border: 1.8px solid #00cc66;
`;
const TagsContainer = styled.div`
  position: absolute;
  top: -8px; 
  right: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
  border: 0px solid red;
`;
const StyledIcon = styled.img`
  position: relative;
  width: 25px;
  height: 25px;
  border: 0px solid red;
  &:hover {
    filter: grayscale(0%) sepia(100%) hue-rotate(90deg) saturate(400%); /* change to color */
  }
`;
export default Card